@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Jomolhari", serif;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer ul li {
  display: inline;
  margin: 0 10px;
}

footer ul li a {
  color: white;
  text-decoration: none;
}

.active {
  opacity: 0.5;
}

.hero {
  background-image: url("./assets/images/hero-image1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cta-section {
  background-image: url("./assets/images/cta_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

@media (min-width: 1024px) {
  .swiper-button-next {
    display: flex !important;
    background-image: url("./assets/images/icon-next.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .swiper-button-prev {
    display: flex !important;
    background-image: url("./assets/images/icon-prev.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after,
  .swiper-button-next::before,
  .swiper-button-prev::before {
    display: none !important;
  }
}

.mission_vision {
  background-image: url("./assets/images/mission_vision_bg.png");
  background-size: "cover";
  background-position: "center";
  background-attachment: "fixed"; /* Keeps the background static while scrolling*/
  height: "100vh";
  width: "100%";
}

@layer components {
  .pry-cta-button {
    @apply w-full  bg-red-800 text-white hover:opacity-70 shadow-md cursor-pointer transition-all duration-500;
  }
  .header-navs {
    @apply uppercase text-sm w-fit mx-auto transition-all duration-500 active:opacity-50 hover:opacity-50;
  }
  .footer-navs {
    @apply w-fit mx-auto transition-all duration-500 active:opacity-20 hover:opacity-70;
  }
  .contact-label {
    @apply text-sm block;
  }
  .contact-input {
    @apply block outline-none border-[1px] border-[#333] w-full p-3 mt-1 text-sm;
  }
}
